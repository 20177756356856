<template>
  <div>
    <section class="home-shipping">
      <div class="row">
        <div class="columns large-6 col-copy">
          <img
            v-lazy-load data-src="~assets/images/homepage-red-bottles.svg"
            alt="Red Wine Bottle Logo"
          />
          <div class="copy-container">
            <h2>
              Never Leave a Bottle on the Table
            </h2>
            <p class="subheader">
              Buy the bottles that catch your eye—we’re happy to hold them for
              up to 30 days, or until you reach the complimentary shipping
              threshold of $150. Then choose your delivery date and you’re all
              set!
            </p>
          </div>
        </div>
        <div class="columns large-3 image-col"></div>
        <div class="columns large-3 col-copy">
          <div class="copy-container">
            <h2>Never Settle</h2>
            <p>
              If any wine fails to impress, we'll credit that bottle.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="home-standard home-collections" style="border-bottom: 0;">
      <div class="row">
        <div class="columns text-center">
          <h2>Featured Collections</h2>
          <div class="row grid-three-up">
            <div
              v-for="o in featuredOccasions"
              :key="o.id"
              class="columns small-12 medium-4"
            >
              <picture>
                <source v-lazy-load :data-srcset="o.thumbnail_optimized" type="image/webp" />
                <source v-lazy-load :data-srcset="o.thumbnail" type="image/jpeg" />
                <img v-lazy-load :data-src="o.image" :alt="`${o.name} Selection`" />
              </picture>
              <h3>{{ o.name }}</h3>
              <nuxt-link :to="`/store/occasion/${o.slug}/`" class="button"
                >Shop the Collection</nuxt-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <SavedSearchModal
      :show="showSavedSearchModal"
      @close="showSavedSearchModal = false"
    />
  </div>
</template>

<script>
export default {
  name: 'FeaturedCollectionsSection',
  components: {
    SavedSearchModal: () => import('~/components/Cellar/SavedSearchModal.vue'),
  },
  data() {
    return {
      occasions: [],
      showSavedSearchModal: false,
    }
  },
  computed: {
    featuredOccasions() {
      return this.occasions.slice(0, 3)
    },
  },
  mounted() {
    this.fetchOccasionsData()
  },
  methods: {
    async fetchOccasionsData() {
      try {
        this.occasions = (
          await this.$axios.get('/api/homepage/homepage-occasions')
        ).data.homepage_occasions
      } catch (error) {
        console.error('Unable to fetch homepage occasions data', error)
      }
    },
  },
}
</script>
